<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">{{ $t("ROLES.ADD_ROLE") }}</h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
          <div class="card-body">
            <role-form
              :loading="loading"
              :roleData="role"
              :formErrors="formErrors"
              @roleSubmitted="handleSubmit"
              @formChanged="() => (alertLeave = true)"
            />
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultRole from "./defaultRole";
import RoleForm from "./partials/RoleForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    RoleForm,
  },

  mixins: [alertLeave],

  data() {
    return {
      role: cloneDeep(defaultRole),
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    goBack() {
      this.$router.push({ name: "List Roles" });
    },

    viewRole(role) {
      this.alertLeave = false;
      this.$router.push({ name: "View Role", params: { id: role.id } });
    },

    async handleSubmit(role) {
      this.loading = true;

      const roleData = cloneDeep(role);
      delete roleData.id;
      if (!roleData.organization.id) {
        delete roleData.organization;
      }
      if (roleData.reseller) {
        if (!roleData.reseller.id) {
          delete roleData.reseller;
        }
      }

      try {
        await this.$store.dispatch("roles/add", roleData);
        this.$notify({
          type: "success",
          message: this.$t("ROLES.ROLE_ADDED"),
        });
        const role = await this.$store.getters["roles/role"];
        this.viewRole(role);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        const role = await this.$store.getters["roles/role"];
        if (role.id) {
          await this.$store.dispatch("roles/destroy", role.id);
        }
        this.loading = false;
      }
    },
  },
};
</script>
